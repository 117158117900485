import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { IconEmail, IconExport, TrashIcon } from 'assets/icons';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { useAuth } from 'core/auth';
import { User } from 'core/auth/types';
import { useAppSelector } from 'hooks';
import {
    CommissionHeaderSwitch,
    CommissionLoadingSchema,
    CommissionSchema,
    useDeleteCommissionMutation,
    useGetOneCommissionQuery,
    usePostInquiryEmailMutation,
    usePutUpdatedCommissionMutation,
} from 'modules/commissions';
import { useEmailMessageSaverType } from 'modules/commissions/hooks/useEmailMessageSaver';
import { useSelectedDispatchers } from 'modules/commissions/hooks/useSelectedDispatchers';
import { buildEnquiryEmailBody } from 'modules/commissions/utils/buildEnquiryEmailBody';
import { DispatcherSearchMapProvider } from 'modules/dispatcherSearch/components/DispatcherSearchMap/context/useDispatcherSearchMapContext';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button, NotFoundPage, Spinner } from 'modules/ui';
import { Subset } from 'types/helpers';

import { CommissionsBasicForm } from '../../../form/components/CommissionsBasicForm/CommissionsBasicForm';
import { Modal } from '../../../ui/components/Modal/Modal';
import { transformCommissionData } from '../../utils/FormattingCommissionData/FormattingCommissionData';
import { SelectedTabOptions } from '../CommissionHeaderSwitch/types';

import styles from './CommissionsDetailPage.module.scss';

export const CommissionsDetailPage = () => {
    const { id } = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { user } = useAuth();

    const [forceSubmitForm, setForceSubmitForm] = useState(false);
    const [showRemoveConfirmationPopup, setShowRemoveConfirmationPopup] = useState(false);
    const [selectedTab, setSelectedTab] = useState<SelectedTabOptions>(SelectedTabOptions.Configuration);
    const [showInquiryModal, setShowInquiryModal] = useState(false);
    const [disableExportButton, setDisableExportButton] = useState(false);
    const { selectedDispatchers } = useSelectedDispatchers();
    const { dispatcherSearchParameters } = useAppSelector((state) => state.commission);
    const {
        data: responseCommission,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetOneCommissionQuery({ id: id || '' });

    useDocumentTitle(t('nav.commission', { id: responseCommission?.number || '' }));

    const [postInquiryEmail] = usePostInquiryEmailMutation();
    const [putUpdatedCommission, { isLoading: isSubmittingFormLoading }] = usePutUpdatedCommissionMutation();
    const [deleteCommission, { isLoading: isSubmittingDeleting }] = useDeleteCommissionMutation();

    if (isLoading || isFetching || !id) return <Spinner fullScreen />;

    if (isError) {
        if ('status' in (error as FetchBaseQueryError)) {
            const fetchError = error as FetchBaseQueryError;

            if (fetchError.status === 404) {
                return <NotFoundPage routeKey="commissions" />;
            }
        }
    }

    // save commission changes
    const prepareData = async ({
        data,
        editedBy,
        oldData,
    }: {
        data: CommissionSchema;
        editedBy: User;
        oldData?: CommissionSchema;
    }) => {
        if (!oldData) return;
        const formatted = transformCommissionData({ currentData: data, editedBy, oldData });

        try {
            const response = await putUpdatedCommission({
                data: formatted,
                id: Number(id),
            }).unwrap();

            toast.success(t('commissions.updated'));
            if (forceSubmitForm) {
                navigate(`${config.routes.commissions.list}/export/${response.commission_id}`);
            }
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    // create commission enquiry
    const handleEmail = async (
        data: useEmailMessageSaverType['messages']['enquiry'],
        loadings: Subset<CommissionLoadingSchema & { companyName: string }>[],
        discharges: Subset<CommissionLoadingSchema & { companyName: string }>[],
    ) => {
        setShowInquiryModal(false);
        if (!selectedDispatchers.length || !user)
            return toast.error(t('commissions.sendCommissionInquiryModal.noEmail'));
        try {
            const res = await postInquiryEmail({
                body: buildEnquiryEmailBody(
                    user,
                    data,
                    selectedDispatchers,
                    loadings,
                    discharges,
                    Number(id),
                    dispatcherSearchParameters,
                ),
            });
            if (res && 'error' in res) throw new Error();
            toast.success(t('commissions.sendCommissionInquiryModal.success'));
            setSelectedTab(SelectedTabOptions.Offer);
        } catch (_) {
            return toast.error(t('commissions.sendCommissionInquiryModal.error'));
        }
    };

    // delete this commission
    const onRemoveItem = async () => {
        try {
            await deleteCommission({
                id: Number(id),
            }).unwrap();

            toast.success(t('commissions.removed'));
            navigate(config.routes.commissions.list);
        } catch (error) {
            toast.error(t('form.saveError'));
        } finally {
            setShowRemoveConfirmationPopup(false);
        }
    };

    return (
        <PageContent
            fullWidthHeader
            fullWidth={selectedTab === SelectedTabOptions.Carrier}
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <div className={styles.actionButtons}>
                            <Button
                                onClick={() => setShowRemoveConfirmationPopup(true)}
                                isLoading={isSubmittingDeleting}
                                disabled={isSubmittingDeleting}
                                className={styles.deleteBtn}
                                type="button"
                                variant="primary"
                                danger
                            >
                                <TrashIcon />
                            </Button>
                            <Button
                                onClick={() => {
                                    setForceSubmitForm(true);
                                }}
                                type="button"
                                variant="secondary"
                                disabled={disableExportButton}
                            >
                                <IconExport />
                                {t('commissions.form.saveAndExport')}
                            </Button>
                            <Button
                                onClick={() => setShowInquiryModal(true)}
                                disabled={selectedDispatchers.length === 0}
                                variant="secondary"
                            >
                                <IconEmail iconBackgroundColor="#1E2124" />
                            </Button>
                            <Button
                                isLoading={isSubmittingFormLoading}
                                disabled={isSubmittingFormLoading}
                                form="commission-form"
                                type="submit"
                            >
                                {t('commissions.form.save')}
                            </Button>
                        </div>
                    }
                    title={`${t('commissions.form.title')} ${responseCommission?.qid ?? ''}`}
                >
                    <CommissionHeaderSwitch onTabSelect={setSelectedTab} selectedTab={selectedTab} />
                </Subheader>
            }
        >
            <DispatcherSearchMapProvider>
                <CommissionsBasicForm
                    fetchedData={responseCommission}
                    prepareData={prepareData}
                    handleEmail={handleEmail}
                    showInquiryModal={showInquiryModal}
                    setShowInquiryModal={setShowInquiryModal}
                    forceSubmitForm={forceSubmitForm}
                    setDisableExportButton={setDisableExportButton}
                    selectedTab={selectedTab}
                />
            </DispatcherSearchMapProvider>

            {/* if we remove the item */}
            {showRemoveConfirmationPopup && (
                <Modal
                    onClick={() => setShowRemoveConfirmationPopup(false)}
                    label={t('commissions.modalRemove.label')}
                    cancelComponent={
                        <Button
                            className={styles.btn}
                            type="button"
                            variant="secondary"
                            onClick={() => setShowRemoveConfirmationPopup(false)}
                        >
                            {t('commissions.modalRemove.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button className={styles.btn} type="button" danger variant="primary" onClick={onRemoveItem}>
                            {t('commissions.modalRemove.approve')}
                        </Button>
                    }
                />
            )}
        </PageContent>
    );
};
