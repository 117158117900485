import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { IconEmail } from 'assets/icons';
import { AnimatePresence } from 'framer-motion';
import { useGetAttachmentQuery, useMailAttachmentFileMutation } from 'modules/attachments/services';
import { EAttachmentType } from 'modules/attachments/types';
import { Button } from 'modules/ui';
import { IMailModalProps, MailModal } from 'modules/ui/components/MailModal';

export interface IMailAttachmentFileButtonProps {
    attachmentId: number;
}

export const MailAttachmentFileButton: React.FC<IMailAttachmentFileButtonProps> = ({ attachmentId }) => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [mailAttachment, { isLoading: isSending }] = useMailAttachmentFileMutation();

    const { isLoading, data, isError } = useGetAttachmentQuery({ attachmentId });

    const isButtonDisabled = useMemo(() => {
        if (isError || isLoading) return true;
        return data?.type === EAttachmentType.DELIVERY_NOTE;
    }, [data, isError, isLoading]);

    const handleMailAttachment: IMailModalProps['onSubmit'] = async (data) => {
        if (isSending) return;
        try {
            await mailAttachment({ attachmentId, email: data.recipients[0], message: data.message }).unwrap();
            toast.success(t('attachments.mail.success'));
            setOpenModal(false);
        } catch (error: any) {
            if (error?.data?.message) {
                return toast.error(error.data.message);
            }
            toast.error(t('attachments.mail.error'));
        }
    };

    return (
        <>
            <AnimatePresence>
                {openModal && (
                    <MailModal
                        initialValues={{
                            attachments: [...(data ? [{ value: data?.name }] : [])],
                            message: '',
                            recipients: [],
                        }}
                        onSubmit={handleMailAttachment}
                        isSubmitting={isSending}
                        title={t('attachments.mail.modal.title')}
                        onOpenChange={setOpenModal}
                    />
                )}
            </AnimatePresence>
            <Button
                title={isButtonDisabled ? t('attachments.mail.modal.hasToBeInvoice') : undefined}
                disabled={isButtonDisabled}
                variant="secondary"
                onClick={() => setOpenModal(true)}
            >
                <IconEmail iconBackgroundColor="#1E2124" />
                {t('common.subheader.email')}
            </Button>
        </>
    );
};
