import 'react-pdf/dist/Page/TextLayer.css';

import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { IconDownload, IconEmail, IconInfo, IconPrint, TrashIcon } from 'assets/icons';
import { config } from 'config';
import {
    useLazyDeleteAttachedFileQuery,
    useLazyGetAttachedFilesListQuery,
    useLazyPostAttachedFileQuery,
} from 'core/api';
import { useDocumentTitle } from 'core/application/hooks';
import { selectAuth } from 'core/auth/services/selectors';
import { User } from 'core/auth/types';
import { useAppSelector } from 'hooks';
import { useListAttachmentsQuery } from 'modules/attachments/services';
import { commissionLanguages } from 'modules/commissions';
import { CustomerDetailSchema } from 'modules/customers';
import { useLazyGetOneCustomerQuery } from 'modules/customers/services';
import { InvoiceBasicForm } from 'modules/form';
import {
    useDeleteInvoiceMutation,
    useGetOneInvoiceQuery,
    useLazyGetInvoicePreviewPdfQuery,
    useLazyPostInvoiceEmailQuery,
    usePutUpdatedInvoiceMutation,
} from 'modules/invoicing/services';
import { AttachedFileListSchema, InvoiceDetailSchema } from 'modules/invoicing/types';
import { transformInvoicingData } from 'modules/invoicing/utils';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button, NotFoundPage, Spinner } from 'modules/ui';
import { IMailModalProps, MailModal } from 'modules/ui/components/MailModal';
import { TMailModalFormSchema } from 'modules/ui/components/MailModal/MailModal.types';
import { downloadURI, printURI, simplifyGetInvoicePdfRequestBody } from 'utils';

import { Modal } from '../../../ui/components/Modal/Modal';
import { InvoiceDocument } from '../InvoiceDocument';

import styles from './InvoicingDetailPage.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

export const InvoicingDetailPage = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle(t('nav.invoicing'));

    // fetch hooks
    const { data: response, isLoading, isFetching, isError, error } = useGetOneInvoiceQuery({ id: id ?? '' });
    const [getPdf] = useLazyGetInvoicePreviewPdfQuery();
    const [postFile] = useLazyPostAttachedFileQuery();
    const [postEmail, { isSuccess, isLoading: isPostingEmail }] = useLazyPostInvoiceEmailQuery();
    const [deleteFile] = useLazyDeleteAttachedFileQuery();
    const [getFilesList] = useLazyGetAttachedFilesListQuery();
    const [getOneCustomer] = useLazyGetOneCustomerQuery();
    const [putUpdatedInvoice, { isLoading: isSubmittingFormLoading }] = usePutUpdatedInvoiceMutation();
    const [deleteInvoice, { isLoading: isSubmittingDeleting }] = useDeleteInvoiceMutation();
    const { data: attachments } = useListAttachmentsQuery(
        {
            invoiceId: response?.invoice_id ?? 0,
            withCommissionsDeliveryNotes: true,
        },
        { skip: !response?.invoice_id },
    );

    // state hooks
    const [blobUrl, setBlobUrl] = useState<string | null>(null);
    const [customer, setCustomer] = useState<CustomerDetailSchema | null>(null);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [forceSubmitForm, setForceSubmitForm] = useState(false);
    const [attachedFilesList, setAttachedFilesList] = useState<AttachedFileListSchema[]>([]);
    const [isSomeValueChanged, setIsSomeValueChanged] = useState<boolean>(false);
    const [showSendEmailConfirmationModal, setShowSendEmailConfirmationModal] = useState(false);
    const [showRemoveConfirmationPopup, setShowRemoveConfirmationPopup] = useState(false);

    // other hooks
    const { user } = useAppSelector(selectAuth);

    const fetchPdf = async (invoice: InvoiceDetailSchema, user: User, customer: CustomerDetailSchema) => {
        setIsLoadingPdf(true);

        if (invoice.commission.length && Number(invoice.pointDate) <= new Date().setHours(0, 0, 0, 0)) {
            const simplifiedBody = simplifyGetInvoicePdfRequestBody(invoice, customer, user);
            const { data: blob } = await getPdf({
                body: simplifiedBody,
            });

            if (!blob) return;
            // Create a Blob from the PDF Stream
            const file = new Blob([blob], { type: 'application/pdf' });
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            setBlobUrl(fileURL);
        }
        setIsLoadingPdf(false);
    };

    // fetch customer
    useEffect(() => {
        if (!response || !user) return;

        const fetchCustomer = async () => {
            const { data: customer, isSuccess } = await getOneCustomer({ id: String(response.customer_id) });

            if (isSuccess) setCustomer(customer);
        };
        fetchCustomer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    const mailAttachments = useMemo(() => {
        const attachmentsToInclude: TMailModalFormSchema['attachments'] = [];
        if (attachments?.length) {
            attachments.forEach(({ attachment_id, name }) => {
                attachmentsToInclude.push({ label: name, value: attachment_id });
            });
        }
        if (attachedFilesList?.length) {
            attachmentsToInclude.push(...attachedFilesList.map((file) => ({ label: file.uri, value: file.uri })));
        }
        return attachmentsToInclude;
    }, [attachments, attachedFilesList]);

    // fetch attached files
    const fetchFiles = async () => {
        if (!response?.invoice_id) return;
        const { data: filesList } = await getFilesList({
            directory: 'invoices',
            id: response.invoice_id,
        });
        if (!filesList) return;
        setAttachedFilesList(filesList.map((uri) => ({ uri })));
    };

    useEffect(() => {
        fetchFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response?.invoice_id]);

    const handleRemoveInvoice = async () => {
        try {
            await deleteInvoice({
                id: Number(id),
            }).unwrap();

            toast.success(t('invoicing.removed'));
            navigate(config.routes.invoicing.list);
        } catch (error) {
            toast.error(t('form.saveError'));
        } finally {
            setShowRemoveConfirmationPopup(false);
        }
    };

    if (isError) {
        if ('status' in (error as FetchBaseQueryError)) {
            const fetchError = error as FetchBaseQueryError;

            if (fetchError.status === 404) {
                return <NotFoundPage routeKey="invoices" />;
            }
        }
    }

    if (isLoading || isFetching || !id || !response || !user) return <Spinner fullScreen />;
    const fetchedData: InvoiceDetailSchema = response;

    const prepareData = async (
        data: InvoiceDetailSchema,
        editedBy: User,
        isAlreadyCreated: boolean,
        oldData?: InvoiceDetailSchema,
    ) => {
        if (!oldData) return;
        setForceSubmitForm(false);

        const formatted = transformInvoicingData({ currentData: data, editedBy, isAlreadyCreated });

        try {
            await putUpdatedInvoice({
                data: { ...formatted, invoiceSent: isSuccess ? true : data.invoiceSent },
                id,
            }).unwrap();
            toast.success(t('invoicing.updated'));
            navigate(config.routes.invoicing.list);
        } catch {
            toast.error(t('form.saveError'));
        }
    };

    const handlePrint = () => {
        if (blobUrl) printURI(blobUrl);
    };

    const handleDownload = () => {
        if (!blobUrl) return;
        downloadURI(blobUrl, `${t('invoicing.form.title')} ${fetchedData.invoiceNumber}`);
        toast.success(t('table.actions.export.message.success'));
    };

    const handleEmail: IMailModalProps['onSubmit'] = async ({ message, recipients, attachments }) => {
        const response = await postEmail({
            data: {
                to: recipients[0],
                dispatcher: { name: user.name, surname: user.surname, phone: user.mobilePhone, email: user.email },
                attachments: attachments,
                body: message,
                lang: commissionLanguages['čeština'],
            },
        });
        setShowSendEmailConfirmationModal(false);

        if (response.isSuccess) {
            toast.success(t('table.actions.email.message.success'));
        } else toast.error(t('table.actions.email.message.error'));
        setForceSubmitForm(true);
    };

    const onAttachFile = async (files: FileList) => {
        const file = files[0];
        if (!fetchedData?.invoice_id) return;
        // after uploading a file, fetch new new actual list again
        const { isSuccess } = await postFile({ file, directory: 'invoices', id: fetchedData.invoice_id });
        if (isSuccess) toast.success(t('invoicing.form.attachedFiles.successfullyUploaded'));

        fetchFiles();
    };
    const onDeleteFile = async (uri: string) => {
        const { isSuccess } = await deleteFile({ filePath: uri });
        if (isSuccess) toast.success(t('invoicing.form.attachedFiles.successfullyDeleted'));

        fetchFiles();
    };

    return (
        <PageContent
            fullWidth
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <div className={styles.actionButtons}>
                            {!fetchedData.exported && (
                                <Button
                                    onClick={() => setShowRemoveConfirmationPopup(true)}
                                    isLoading={isSubmittingDeleting}
                                    disabled={isSubmittingDeleting}
                                    className={styles.deleteBtn}
                                    type="button"
                                    variant="primary"
                                    danger
                                >
                                    <TrashIcon />
                                </Button>
                            )}

                            <Button onClick={handlePrint} disabled={isSomeValueChanged || !blobUrl} variant="secondary">
                                <IconPrint />
                                {t('common.subheader.print')}
                            </Button>
                            <Button
                                onClick={handleDownload}
                                disabled={isSomeValueChanged || !blobUrl}
                                variant="secondary"
                            >
                                <IconDownload />
                                {t('common.subheader.download')}
                            </Button>
                            <Button
                                onClick={() => setShowSendEmailConfirmationModal(true)}
                                disabled={isSomeValueChanged}
                                variant="secondary"
                            >
                                <IconEmail iconBackgroundColor="#1E2124" />
                                {t('common.subheader.email')}
                            </Button>
                            <Button
                                isLoading={isSubmittingFormLoading}
                                disabled={isSubmittingFormLoading}
                                form="invoice-form"
                                type="submit"
                            >
                                {t('invoicing.form.save')}
                            </Button>
                        </div>
                    }
                    title={`${t('invoicing.form.title')} ${fetchedData?.invoiceNumber || ''}`}
                />
            }
        >
            {fetchedData && (
                <div className={styles.container}>
                    <div className={styles.form}>
                        <InvoiceBasicForm
                            fetchedData={fetchedData}
                            attachedFilesList={attachedFilesList}
                            onAttachFile={onAttachFile}
                            onDeleteFile={onDeleteFile}
                            customer={customer}
                            prepareData={prepareData}
                            setIsSomeValueChanged={setIsSomeValueChanged}
                            forceSubmitForm={forceSubmitForm}
                            fetchPdf={fetchPdf}
                        />
                    </div>
                    <InvoiceDocument blobUrl={blobUrl} loading={isLoadingPdf} />
                </div>
            )}
            {showSendEmailConfirmationModal && (
                <MailModal
                    title={t('invoicing.sendEmailModal.label')}
                    isSubmitting={isPostingEmail}
                    onSubmit={handleEmail}
                    maxRecipients={1}
                    initialValues={{
                        attachments: mailAttachments,
                        message: t('invoicing.sendEmailModal.message'),
                        recipients: [...(customer?.email ? [{ value: customer?.email, label: customer?.email }] : [])],
                    }}
                    infoSlot={
                        <div className={styles.emailModalInfo}>
                            <IconInfo color="#738291" /> {t('invoicing.sendEmailModal.info')}
                        </div>
                    }
                    onOpenChange={setShowSendEmailConfirmationModal}
                />
            )}
            {showRemoveConfirmationPopup && (
                <Modal
                    onClick={() => setShowRemoveConfirmationPopup(false)}
                    label={t('invoicing.modalRemove.label')}
                    cancelComponent={
                        <Button
                            className={styles.btn}
                            type="button"
                            variant="secondary"
                            onClick={() => setShowRemoveConfirmationPopup(false)}
                        >
                            {t('invoicing.modalRemove.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button
                            className={styles.btn}
                            type="button"
                            danger
                            variant="primary"
                            onClick={handleRemoveInvoice}
                        >
                            {t('invoicing.modalRemove.approve')}
                        </Button>
                    }
                />
            )}
        </PageContent>
    );
};
