import 'core/translation';
import 'styles/_global.scss';

import { Suspense } from 'react';
import { I18nProvider } from 'react-aria';
import { MapProvider } from 'react-map-gl';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { config } from 'config';
import { Authenticated, LoginForm, LogoutPage } from 'core/auth';
import { ErrorPage } from 'core/error';
import { Sentry } from 'core/error/components/Sentry';
import { AttachmentsUploadPage } from 'modules/attachments/components/AttachmentsUploadPage';
import { CarrierDetailPage, CarriersNewPage, CarriersPage } from 'modules/carriers';
import { CommissionsDetailPage, CommissionsExport, CommissionsNewPage, CommissionsPage } from 'modules/commissions';
import { SelectedDispatchersProvider } from 'modules/commissions/hooks/useSelectedDispatchers';
import { ProvidersWrapper } from 'modules/common/components/ProvidersWrapper';
import { CustomersPage } from 'modules/customers';
import { CustomersDetailPage } from 'modules/customers/components/CustomersDetailPage';
import { CustomersNewPage } from 'modules/customers/components/CustomersNewPage';
import { InvoicingDetailPage, InvoicingPage } from 'modules/invoicing';
import { InvoicingNewPage } from 'modules/invoicing/components/InvoicingNewPage';
import { OffersDetailPage, OffersPage } from 'modules/offers';
import { DispatcherDetailPage, DispatcherNewPage, OnboardingPage } from 'modules/onboarding';
import { Profile } from 'modules/profile';
import { Spinner } from 'modules/ui';
import { store } from 'store';
import { EditAttachmentPage } from 'modules/attachments/components/EditAttachmentPage';
import { CreateAttachmentPage } from 'modules/attachments/components/CreateAttachmentPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Authenticated />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: config.routes.auth.logout,
                element: <LogoutPage />,
            },
            {
                path: config.routes.commissions.list,
                element: <CommissionsPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.commissions.detail,
                element: (
                    <SelectedDispatchersProvider>
                        <CommissionsDetailPage />
                    </SelectedDispatchersProvider>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.commissions.new,
                element: (
                    <SelectedDispatchersProvider>
                        <CommissionsNewPage />
                    </SelectedDispatchersProvider>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.commissions.export,
                element: <CommissionsExport />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.carriers.list,
                element: <CarriersPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.carriers.detail,
                element: <CarrierDetailPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.carriers.new,
                element: <CarriersNewPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.customers.list,
                element: <CustomersPage />,
            },
            { path: config.routes.customers.detail, element: <CustomersDetailPage />, errorElement: <ErrorPage /> },
            { path: config.routes.customers.new, element: <CustomersNewPage />, errorElement: <ErrorPage /> },
            {
                path: config.routes.invoicing.list,
                element: <InvoicingPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.invoicing.detail,
                element: <InvoicingDetailPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.invoicing.new,
                element: <InvoicingNewPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.offers.list,
                element: <OffersPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.offers.detail,
                element: (
                    <SelectedDispatchersProvider>
                        <MapProvider>
                            <OffersDetailPage />
                        </MapProvider>
                    </SelectedDispatchersProvider>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.dispatcher.detail,
                element: <DispatcherDetailPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.dispatcher.new,
                element: <DispatcherNewPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.profile,
                element: <Profile />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.attachments.detail,
                element: <EditAttachmentPage />
            },
            {
                path: config.routes.attachments.new,
                element: <CreateAttachmentPage />
            }
        ],
    },
    { path: config.routes.dispatcher.onboarding, element: <OnboardingPage />, errorElement: <ErrorPage /> },
    {
        path: config.routes.attachments.upload,
        element: <AttachmentsUploadPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: config.routes.auth.login,
        element: <LoginForm />,
        errorElement: <ErrorPage />,
    },
]);

export const Application = () => (
    <Sentry>
        <Provider store={store}>
            <I18nProvider locale="cs-CZ">
                <Suspense fallback={<Spinner fullScreen />}>
                    <ProvidersWrapper>
                        <RouterProvider router={router} />
                    </ProvidersWrapper>
                </Suspense>
            </I18nProvider>
        </Provider>
    </Sentry>
);
