import { User } from 'core/auth/types';
import { ActualCommissionSchema } from 'modules/commissions';
import { InvoiceSchema } from 'modules/invoicing';
import z from 'zod';

export enum EAttachmentType {
    INVOICE = 'INVOICE',
    DELIVERY_NOTE = 'DELIVERY_NOTE',
}

export const attachmentUploadFormSchema = () => {
    return z
        .object({
            invoice: z.array(z.object({ file: z.instanceof(File) })).max(1),
            deliveryNotes: z.array(z.object({ file: z.instanceof(File) })),
        })
        .refine((data) => data.invoice.length > 0 || data.deliveryNotes.length > 0, {
            path: ['invoice', 'deliveryNotes'],
        });
};

export type TAttachmentUploadFormSchema = z.infer<ReturnType<typeof attachmentUploadFormSchema>>;

export * from './api';
export * from './forms';

export type TAttachment = {
    attachment_id: number;
    name: string;
    fileName: string;
    type: EAttachmentType;
    sent: boolean;
    tsAdded: bigint;
    deleted: boolean;
    user_id?: number;
    uploadedBy?: User;
    commission_id?: number;
    commission?: ActualCommissionSchema;
    invoice_id?: number;
    invoice?: InvoiceSchema;
  };